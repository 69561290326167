import React from 'react'
import ggif from '../images/ggif.png'
import DetailsSection from '../components/DetailsSection'
import Footer from '../components/Footer'
import Layout from '../layouts'

const GoodGovernanceIndiaFoundationPage = () => (
  <Layout>
    <DetailsSection
      id="good-governance-india-foundation"
      src={ggif}
      buttonLink="http://www.goodgovernanceindia.com/"
      buttonText="Know More"
      headerText="GGIF is a think-tank dedicated to promoting value of property rights under constitutional rule of law. It advocates for free and fair land markets as the best arbiter of what economic projects are in real public interest. GGIF also advocates for public policy reforms based on democratic principles of decentralization and primacy of Individual human rights."
    />
    <Footer />
  </Layout>
)
export default GoodGovernanceIndiaFoundationPage
